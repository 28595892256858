import React from "react";

const FranchiseTopics = ({ additionalClass = "" }) => {
  const topicsList = [
    // {
    //   label:
    //     "Franchise Financing : Funding options and strategies for new franchisees",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/franchise-financing-funding-options-and-strategies-for-new-franchisees",
    // },
    // {
    //   label:
    //     "Franchise Frauds: 5 red flags to watch for when evaluating a franchise opportunity",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/franchise-frauds-5-red-flags-to-watch-for-when-evaluating-a-franchise-opportunity",
    // },
    // {
    //   label: "Tips for managing a successful franchise business",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/tips-for-managing-a-successful-franchise-business",
    // },
    // {
    //   label: "6 Common mistakes of franchise investor",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/6-common-mistakes-of-franchise-investor",
    // },
    // {
    //   label: "5 franchising terms you need to know before buying a franchise",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/5-franchising-terms-you-need-to-know-before-buying-a-franchise",
    // },
    // {
    //   label:
    //     "What are the benefits of taking franchise instead of starting your own brand?",
    //   link: "https://franchisediscovery.in/blogdetail/faqs-franchises/what-are-the-benefits-of-taking-franchise-instead-of-starting-your-own-brand",
    // },

    // {
    //   label:
    //     "Franchise Financing : Funding options and strategies for new franchisees",
    //   link: "https://franchisediscovery.in/content/news/franchise-financing-:-funding-options-and-strategies-for-new-franchisees-franchise?id=6687c2312763af91c12fb6d6",
    // },
    // {
    //   label:
    //     "Franchise Frauds: 5 red flags to watch for when evaluating a franchise opportunity",
    //   link: "https://franchisediscovery.in/content/news/franchise-frauds:-5-red-flags-to-watch-for-when-evaluating-a-franchise-opportunity-franchise?id=6687dd7a2763af91c12fb786",
    // },
    // {
    //   label: "Tips for managing a successful franchise business",
    //   link: "https://franchisediscovery.in/content/news/tips-for-managing-a-successful-franchise-business-franchise?id=6687e00b2763af91c12fb7b1",
    // },

    {
      label:
        "Uncle Peter’s Pancakes: A Delicious Journey from Parisian Inspiration to Indian Hearts",
      link: "https://franchisediscovery.in/content/food--beverages/uncle-peter%E2%80%99s-pancakes:-a-delicious-journey-from-parisian-inspiration-to-indian-hearts-franchise?id=67495dda8d487120be1263a2",
    },
    {
      label: "Top 50 education franchise brands in India 2024-25",
      link: "https://franchisediscovery.in/content/faq/top-50-education-franchise-brands-in-india-2024-25-franchise?id=674ad6548d487120be133d0f",
    },
    {
      label: "10 Most Profitable Franchise Business Opportunities in India",
      link: "https://franchisediscovery.in/content/faq/10-most-profitable-franchise-business-opportunities-in-india-franchise?id=672dacb58d487120be0462b3",
    },
    {
      label: "Retail Franchise Business in India",
      link: "https://franchisediscovery.in/content/retail/retail-franchise-business-in-india-franchise?id=6687ca612763af91c12fb71c",
    },
    {
      label:
        "Franchise Frauds: 5 Red flags to watch for when evaluating a franchise opportunity",
      link: "https://franchisediscovery.in/content/faq/franchise-frauds:-5-red-flags-to-watch-for-when-evaluating-a-franchise-opportunity-franchise?id=6687dd7a2763af91c12fb786",
    },
    {
      label:
        "Franchise Financing : Funding Options and Strategies for New Franchisees",
      link: "https://franchisediscovery.in/content/faq/franchise-financing-:-funding-options-and-strategies-for-new-franchisees-franchise?id=6687c2312763af91c12fb6d6",
    },
    {
      label: "Supermarket franchise business in India",
      link: "https://franchisediscovery.in/content/retail/supermarket-franchise-business-in-india-franchise?id=6687c9d82763af91c12fb718",
    },
  ];

  return (
    <div className={additionalClass}>
      <div className="mb-[30px] flex flex-col  items-start justify-start gap-x-[8px]">
        <h2 className="mb-3 text-lg font-medium  lg:text-xl lg:font-semibold">
          Franchise Topics
        </h2>
        <ul
          role="list"
          className="list-disc space-y-2 pl-5 text-sm font-medium text-color-grey-4 marker:text-color-primary lg:text-base lg:font-normal lg:text-color-primary-dark"
        >
          {topicsList.map((topic, index) => (
            <li key={index}>
              <a href={topic.link} target="_blank">
                {topic.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FranchiseTopics;
